import React from 'react'
import classnames from "classnames";
import style from "./form.module.scss";

interface EmbedFormProps {
    url: string | URL
    style?: string
    allowfullscreen?: boolean
}

export const EmbedForm = (props: EmbedFormProps) => {
    const { url, allowfullscreen = true } = props;
    
    // const data: {[name: string]: Array<LinkItem>} = useSelector((state: RootState) => state.ButtonGridSlice.data);

    return (
        <iframe className={classnames(style.embeddedForm)} title='embed' src={url+"&embed=true"} allowFullScreen={allowfullscreen}>Your browser must support iframes to display this form.</iframe>
    )
}