import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LinkItem } from 'models/Links/LinkItem';

export interface MainState {
    DisplayedForm?: LinkItem
    ConnectedToGoogle: boolean
}

const initialState = {
    ConnectedToGoogle: false,
    DisplayedForm: undefined
} as MainState;

const MainSlice = createSlice({
    name: 'MainSlice',
    initialState,
    reducers: {
        setIframe(state, action: PayloadAction<LinkItem | undefined>) {
            state.DisplayedForm = action.payload;
        },
        setConnectedToGoogle(state, action: PayloadAction) {
            state.ConnectedToGoogle = true;
        }
    }
});

export const { setIframe, setConnectedToGoogle } = MainSlice.actions;

export default MainSlice.reducer;