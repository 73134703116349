import React from 'react';
import styles from './App.module.scss';
import MainScreen from 'features/MainScreen';
import classNames from 'classnames';

export default function App() {
  return (
    <div className={classNames(styles.App)}>
      <MainScreen/>
    </div>
  );
}

