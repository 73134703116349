import { combineReducers } from '@reduxjs/toolkit';

import SheetSelectorSlice from 'features/SheetSelector/SheetSelectorSlice';
import ButtonGridSlice from 'features/ButtonGrid/ButtonGridSlice';
import LoginSlice from 'features/LoginScreen/LoginSlice';
import MainSlice from 'features/MainScreen/MainSlice';

const rootReducer = combineReducers({
    SheetSelectorSlice,
    ButtonGridSlice,
    LoginSlice,
    MainSlice
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;