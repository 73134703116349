import React, { useEffect } from 'react';
import { getSheets, loadGoogleClient } from 'api/GoogleSheets';
import { setData, setSelection } from '../SheetSelector/SheetSelectorSlice'
import SheetSelector, { OptionProps } from '../SheetSelector/SheetSelector'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import LoadScreen from 'features/loading/Loading';
import { Grid } from '@material-ui/core';
import { ButtonGrid } from 'features/ButtonGrid';
import { EmbedForm } from 'features/EmbedForm';
import { setConnectedToGoogle, setIframe } from './MainSlice';
import LoginScreen from 'features/LoginScreen';
import mascot from '../../images/mascot.png'
import styles from './main.module.scss';

export default function MainScreen() {
    const dispatch = useDispatch();
    
    const { active, data } = useSelector((state: RootState) => state.SheetSelectorSlice);
    const optionProps = data.map((item) => {
        return {
            displayedText: item.title,
            value: item.sheetId
        } as OptionProps
    });
    const { ConnectedToGoogle, DisplayedForm } = useSelector((state: RootState) => state.MainSlice);
    const { LoggedIn } = useSelector((state: RootState) => state.LoginSlice);

    let loadingText = "Connecting to Google";
    
    const handleChange = (e: any) => {
        // check input is valid
        if (e.target.value) {
            dispatch(setIframe(undefined));
            dispatch(setSelection(e.target.value));
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (ConnectedToGoogle || data.length > 0) return;
            loadGoogleClient()
                .then(() => {
                    dispatch(setConnectedToGoogle());
                    loadingText = "Getting sheet data";
                    getSheets()
                        .then((data)=> {                        
                            dispatch(setData(data));
                        })
                        .catch(() => {
                            loadingText = "Failed to fetch sheet data";
                        })
    
                })
                .catch(() => {
                    loadingText = "Failed to connect";
                })
        }
        fetchData();
    }, [])

    if (!LoggedIn) {
        return (<LoginScreen />)
    }

    if (data.length === 0) {
        return (
            <>
                <LoadScreen text={loadingText}/>
            </>
        );
    }

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
        >
            {!DisplayedForm && <img className={styles.mascot} src={mascot}/>}
            <span className={styles.heading}>Let's get started</span>
            <span className={styles.please}>Please select:</span>
            <SheetSelector handleChange={handleChange} options={optionProps} selected = {active}/>
            {
                active && (
                    <ButtonGrid selected={active as unknown as string}/>
                )                
            }    
            <Grid
                item
                justify="center"
                alignItems="center">
            {
                DisplayedForm && (
                    // <div dangerouslySetInnerHTML={{__html:DisplayedForm.embed}}/>
                    <EmbedForm url={DisplayedForm.url}/>
                )
            }
            </Grid>
        </Grid>
    );
}