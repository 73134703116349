import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LinkSheet } from 'models/Links/LinkSheet';

export interface SelectState {
    data: Array<LinkSheet>
    active: number | undefined
}

const initialState = {
    data: [],
    active: undefined
} as SelectState;

const SheetSelectorSlice = createSlice({
    name: 'SheetSelector',
    initialState,
    reducers: {
        setSelection(state, action: PayloadAction<number>) {
            state.active = action.payload;
        },
        setData(state, action: PayloadAction<Array<LinkSheet>>) {
            state.data = action.payload;
        }
    }
});

export const { setData, setSelection } = SheetSelectorSlice.actions;

export default SheetSelectorSlice.reducer;