import React, { useState } from 'react';
import classnames from 'classnames';
import { Face, Fingerprint } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, TextField, Button, Snackbar, Fade } from '@material-ui/core';
import yellow from '@material-ui/core/colors/yellow';
import { setUsername, setPassword, login } from './LoginSlice';
import { RootState } from 'app/rootReducer';

import logo from '../../images/logo.png';
import styles from './login.module.scss';

export interface LoginScreenProps {
}

export default function LoginScreen(props: LoginScreenProps) {
    const dispatch = useDispatch();
    const { username, password } = useSelector((state: RootState) => state.LoginSlice);
    const [open, setOpen] = useState(false);

    const buttonOverride = (theme: any) => ({
        containedBms: {
            color: '#6BCFB3',
            backgroundColor: "#FFDA88",
            "@media (hover: none)": {
                backgroundColor: yellow[700],
            }
        }
    });

    const handleChange = (e: any) => {
        // check input is valid
        if (e.target) {
            switch(e.target.id) {
                case 'username':
                    dispatch(setUsername(e.target.value));
                break;
                case 'password':
                    dispatch(setPassword(e.target.value));
                break;
            }
        }
    };

    const validLogin = () => {
        switch (username) {
            case 'russell': return (password === 'supersecretpassword');
            case 'shoey': return (password === 'taurangaisthebest');
            case 'buckler': return (password === 'uniteagainstcovid');
            case 'bmscameronroad': return (password === 'shoebuckle');
            default: return false;
        }
    }

    const onClickLogin = () => {
        if (validLogin()) {
            dispatch(login());
        }
        else {
            setOpen(true)
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    // const renderOldForm = (
    //     <Paper>
    //         <Snackbar 
    //             anchorOrigin={{vertical: 'top', horizontal: 'center'}}
    //             autoHideDuration={3000}
    //             TransitionComponent={Fade}
    //             onClose={handleClose}
    //             open={open}
    //         >
    //             <Alert severity='error'>Invalid login</Alert>
    //         </Snackbar>
    //         <div>
    //             <Grid container spacing={8} alignItems='flex-end' justify='space-around'>
    //                 <Grid item>
    //                     <Face/>
    //                 </Grid>
    //                 <Grid item md={true} sm={true} xs={true}>
    //                     <TextField id='username' label='Username' onChange={handleChange} value={username} fullWidth autoFocus required/>
    //                 </Grid>
    //             </Grid>
    //             <Grid container spacing={8} alignItems='flex-end'>
    //                 <Grid item>
    //                     <Fingerprint/>
    //                 </Grid>
    //                 <Grid item md={true} sm={true} xs={true}>
    //                     <TextField id='password' label='Password' onChange={handleChange} type='password' value={password} fullWidth required/>
    //                 </Grid>
    //             </Grid>
    //             <Grid container justify='center' style={{marginTop: '10px', paddingBottom: '10px'}}>
    //                 <Button variant='outlined' color='primary' onClick={onClickLogin}>Login</Button>
    //             </Grid>
    //         </div>
    //     </Paper>
    // );

    const bmsLoginForm = (
        <div className={classnames(styles.container)}>
            <img className={classnames(styles.logo)} src={logo} alt="logo" />
            <span className={classnames(styles.welcome)}>Welcome!</span>
            <span className={classnames(styles.please)}>Please log in to continue:</span>
            <div className={classnames(styles.loginForm)}>
                <TextField 
                    id='username' label='Username' value={username} 
                    variant="outlined" inputProps={{style:{ textAlign: "center"}}}
                    onChange={handleChange} onKeyUp={(e)=>{e.key === 'Enter' && onClickLogin()}} fullWidth autoFocus required
                />
                <TextField 
                    id='password' label='Password' type='password' value={password} 
                    variant="outlined" inputProps={{style:{ textAlign: "center"}}}
                    onChange={handleChange} onKeyUp={(e)=>{e.key === 'Enter' && onClickLogin()}} fullWidth autoFocus required
                />
                <Button variant='contained' color='default' onClick={onClickLogin} className={classnames(styles.loginButton)}>LOG IN</Button>
            </div>
             <Snackbar 
                 anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                 autoHideDuration={3000}
                 TransitionComponent={Fade}
                 onClose={handleClose}
                 open={open}
             >
                 <Alert severity='error'>Invalid login</Alert>
             </Snackbar>
        </div>
    );

    return bmsLoginForm;
}