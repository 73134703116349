import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { LinkItem } from 'models/Links/LinkItem'
import { getSheetValues } from 'api/GoogleSheets';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { setData } from './ButtonGridSlice';
import LoadScreen from 'features/loading/Loading';
import { ItemButton } from './ItemButton';

interface ButtonGridProps {
    selected: string
}

export const ButtonGrid = (props: ButtonGridProps) => {
    const { selected } = props;
    const dispatch = useDispatch();
    
    const data: {[name: string]: Array<LinkItem>} = useSelector((state: RootState) => state.ButtonGridSlice.data);
    const linkItems = data[selected];
    let loadingText = "Fetching Buttons";

    const fetchLinkItems = async () => {
        if (!linkItems) {
            getSheetValues(selected)
                .then((data: LinkItem[])=> {
                    
                    console.log(data)
                    dispatch(setData({
                        data,
                        sheetName: selected
                    }));
                })
                .catch((reason) => {
                    loadingText = "Failed to fetch sheet values";
                    console.log(reason);
                })
        }
    }

    useEffect(() => {
        fetchLinkItems();
    }, [fetchLinkItems])

    if (!linkItems) {
        return (
            <LoadScreen text={loadingText}/>
        );
    }

    return (
        <Grid
            container
            item
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
        >
            {
                linkItems.map((item, index) => <ItemButton {...item} key={index}/>)
            }

        </Grid>
    )
}