import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LoginState {
    username: string
    password: string
    LoggedIn: boolean
}

const initialState = {
    username: '',
    password: '', 
    LoggedIn: false
} as LoginState;

const LoginSlice = createSlice({
    name: 'LoginSlice',
    initialState,
    reducers: {
        setUsername(state, action: PayloadAction<string>) {
            state.username = action.payload;
        },
        setPassword(state, action: PayloadAction<string>) {
            state.password = action.payload;
        },
        login(state) {
            state.LoggedIn = true;
        }
    }
});

export const { setUsername, setPassword, login } = LoginSlice.actions;

export default LoginSlice.reducer;