import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { CubeLoader } from './cube';
import { DashLoader } from './dash';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex', 
            fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace",
            fontWeight: 'bolder',
            alignItems: 'center',
            flexDirection: 'column',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            }
        },
        span: {
            marginBottom: '40%'
        }
    })
);

export enum LoaderType {
    Invalid,
    Cube,
    Goog,
    Dash
}

interface LoadscreenProps {
    text: string;
    type?: LoaderType;
}

export default function LoadScreen(props: LoadscreenProps) {
    const { text = "Loading", type = LoaderType.Cube } = props;
    const classes = useStyles();

    const GetLoader = (type?: LoaderType) => {
        switch(type) {
            case LoaderType.Dash: return <DashLoader/>;
            case LoaderType.Cube: return <CubeLoader/>;
        }
    }

    return (
        <div className={classes.root}>
            <span className={classes.span}>{text}</span>
            {GetLoader(type)}
        </div>
    );
}