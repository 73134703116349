import React from "react";
import classnames from "classnames";
import style from "./dash.module.scss";

export const DashLoader = () => (
    <div className={style.container}>
        <div className={classnames(style.dash, style.uno)}></div>
        <div className={classnames(style.dash, style.dos)}></div>
        <div className={classnames(style.dash, style.tres)}></div>
        <div className={classnames(style.dash, style.cuatro)}></div>
    </div>
)