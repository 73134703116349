import { apiKey, clientId, discoveryDocs, scope, spreadsheetId } from 'config.json'
import { LinkSheet } from 'models/Links/LinkSheet';
import { LinkItem } from 'models/Links/LinkItem';

/**
 * optional step as our sheet is public
 */
export const authorize = (callback: ()=>any) => {
    gapi.auth.authorize({
        immediate: true,
        client_id: clientId,
        scope
    },
    callback);
}

export const loadGoogleClient = async (callback?: ()=>any) => {
    return new Promise((resolve, reject) => {
        const URI = "https://apis.google.com/js/api.js";
        const script = document.createElement("script");
        script.src = URI;
        script.async = true;
        script.defer = true;
        script.onload = (ev) => {
            console.warn('gapi successfully loaded: '+ev);
            gapi.load('client', () => {            
                gapi.client.init({
                    apiKey,
                    clientId,
                    discoveryDocs,
                    scope
                }).then(_=>{
                    console.warn('sheets api inialized');
                    callback&&callback();
                    resolve();
                },(error) => {
                    console.error('error loading sheets api', error);
                    reject();
                })
            });
        } 
        document.body.appendChild(script);
    })
}

/**
 * retrieve spreadsheet title and list of tabs
 */
export const getSheets = async () => {

    return new Promise<Array<LinkSheet>>((resolve, reject) => {
        gapi.client.sheets.spreadsheets.get({
          spreadsheetId,
        }).then((response) => {
          const {properties, sheets} = response.result;
          let sheetArr:Array<LinkSheet> = [];
    
          if (!sheets || !properties) throw new Error("unexpected response")
    
          const { title } = properties;
    
          if (sheets.length > 0) {
    
              for(let i = 0; i < sheets.length; i++) {
                  const { properties } = sheets[i]
                  const { index, sheetId, title } = properties!;
                  sheetArr.push({
                      title,
                      index,
                      sheetId
                  } as LinkSheet)
              }
          
          } else {
              reject('fail')
          }
          resolve(sheetArr)
          console.log('sheet:', title, 'tabs:', sheetArr);
        }, (response) => {
            reject(response);
        });
    });
}

export const getSheetValues = (sheetName: string) => {
    return new Promise<Array<LinkItem>>((resolve, reject) => {
        gapi.client.sheets.spreadsheets.values.get({        
            spreadsheetId,
            range: `'${sheetName}'!A2:E`
        }).then((response) => {
            const {values} = response.result;
            console.warn(response.result);
            let linkItems: Array<LinkItem> = [];
        
            if (!values) {
                reject('unexpected response')
                return;
            }        
            if (values.length > 0) {        
                for(let i = 0; i < values.length; i++) {
                    const row = values[i]
                    const [name, url, image, tags, embed] = row;   
                    
                    linkItems.push(createLinkItem(name, url, image, tags, embed))
                }            
                resolve(linkItems);
            } else {
                reject('no values')
            }
            console.log('rows', linkItems);
        })
        .catch((reason)=> {
            reject(reason)
        });
    })
}

function createLinkItem (name: string, url: string, image: string, tags: string, embed: string): LinkItem {
    return {
        name,
        url: new URL(url),
        image,
        tags: tags.split(',').map(tag=>tag.trim()),
        embed
    } as LinkItem
}