import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LinkItem } from 'models/Links/LinkItem';

export interface ButtonData {
    data: {[sheetId: string]:Array<LinkItem>}
    active: string | undefined
}

const initialState = {
    data: {},
    active: undefined
} as ButtonData;

interface SheetLinks {
    data: Array<LinkItem>
    sheetName: string
}

const ButtonGridSlice = createSlice({
    name: 'ButtonGrid',
    initialState,
    reducers: {
        setData(state, action: PayloadAction<SheetLinks>) {
            state.data[action.payload.sheetName] = action.payload.data;
            state.active = action.payload.sheetName;
        },
        setActive(state, action: PayloadAction<string>) {
            state.active = action.payload;
        }
    }
});

export const { setData, setActive } = ButtonGridSlice.actions;

export default ButtonGridSlice.reducer;