import React from "react";
import { LinkItem } from "models/Links/LinkItem";
import { Grid, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setIframe } from "features/MainScreen/MainSlice";
import styles from './button.module.scss'

export const ItemButton = (prop: LinkItem) => {
    const { image, name } = prop;
    const dispatch = useDispatch();

    return (
        <Grid
            item
            direction="row"
            justify="center"
            alignItems="center"           
        >
            <Button 
                className={styles.button}
                variant='contained' 
                color='primary'
                onClick={()=>{dispatch(setIframe(prop))}}
            >
                {image && <img src={image} alt={name} width="64px" height="64px"/>}
                {name}
            </Button>
                        
        </Grid>
    )

}