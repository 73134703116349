import React from 'react';
import classnames from 'classnames';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styles from './select.module.scss';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

export type SelectorValue = string | number | string[]

export interface OptionProps {
    displayedText: string
    value: string | number | string[]
}

export interface SheetSelectorProps {
    options: OptionProps[];
    selected?: SelectorValue;
    handleChange: (e: any) => void;
}

export default function SheetSelector(props: SheetSelectorProps) {
    const { handleChange, options, selected = '' } = props;
    const classes = useStyles();

    return (
        <>
            <FormControl className={classnames(classes.formControl)}>
                <Select
                    id="category-selection"
                    value={selected}
                    onChange={handleChange}
                    variant='outlined'
                    className={styles.selector}
                >
                    <MenuItem value=''>--Please Select--</MenuItem>
                {
                    options.map((item, index) => {
                        return (<MenuItem key={index} value={item.displayedText}>{item.displayedText}</MenuItem>)
                    })
                }
                </Select>
            </FormControl>
        </>
    );
}